// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-blog-post-js": () => import("./../../../src/components/blog-post.js" /* webpackChunkName: "component---src-components-blog-post-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-github-js": () => import("./../../../src/pages/github.js" /* webpackChunkName: "component---src-pages-github-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

